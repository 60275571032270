import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Layout from "./components/Layout";
import Gallery from "./pages/Gallery";
import Bends from "../src/pages/GalleryCategories/Bends";
import Flanged from "../src/pages/GalleryCategories/Flanged-Pipes-Fittings";
import Coatings from "../src/pages/GalleryCategories/Coatings-and-Linings";
import Fittings from "../src/pages/GalleryCategories/Shouldered-Pipe-and-Fittings";
import Other from "../src/pages/GalleryCategories/Other";
import GalleryAll from "../src/pages/GalleryCategories/Gallery-all";
import NoPage from "./pages/NoPage";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}></Route>
        <Route path="/Gallery" element={<Gallery />}></Route>
        <Route path="/Bends" element={<Bends />}></Route>
        <Route path="/Flanged-Pipes-Fittings" element={<Flanged />}></Route>
        <Route path="/Coatings-and-Linings" element={<Coatings />}></Route>
        <Route path="/Other" element={<Other />}></Route>
        <Route path="/Gallery-all" element={<GalleryAll />}></Route>
        <Route
          path="/Shouldered-Pipe-and-Fittings"
          element={<Fittings />}
        ></Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React from "react";
import { Row, Col, Form, Input, Button, Card } from "antd";
import styled, { css, keyframes } from "styled-components";
import HoriLine from "../static/Home/hori-line.svg";
import Cat1 from "../static/GalleryView/cat-1.png";
import Cat2 from "../static/GalleryView/cat-2.png";
import Cat3 from "../static/GalleryView/cat-3.png";
import Cat4 from "../static/GalleryView/cat-4.png";
import Cat5 from "../static/GalleryView/cat-5.png";
import Cat6 from "../static/GalleryView/cat-6.png";
import Cat7 from "../static/GalleryView/cat-7.png";
import Cat8 from "../static/GalleryView/cat-8.png";
import Cat9 from "../static/GalleryView/cat-9.png";
import Cat10 from "../static/GalleryView/cat-10.png";

import Header from "..//components/Header";
import Footer from "..//components/Footer";
import { ArrowRightOutlined } from "@ant-design/icons";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";

const ProductsHeader = () => (
  <div>
    <Fade bottom>
      <h1
        className="Mulish"
        style={{
          margin: "auto",
          fontWeight: 800,
          fontSize: "35px",
        }}
      >
        BCC PRODUCT RANGE
      </h1>
      <p
        className="Mulish"
        style={{
          fontSize: 18,
          marginTop: "1.5rem",
          color: "black",
        }}
      >
        We accommodate pipe sizes ranging from 50nb - 500nb 0m - 10m
      </p>{" "}
    </Fade>
  </div>
);
const ProductsContent = () => (
  <div>
    <Row style={{ maxWidth: "90em", margin: "auto" }}>
      <Col sm={24} md={8}>
        <Link to="/Gallery-all">
          <Card
            className="category-card-hover"
            style={{
              margin: "1em",
              padding: "1em",
              height: "15em",
              borderRadius: "1em",
              border: "none",
              textAlign: "center",
              backgroundImage: `url(${Cat1})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          >
            <img
              style={{
                margin: "auto",
                marginTop: "4em",
                textAlign: "center",
              }}
            ></img>
            <h1
              className="Mulish"
              style={{
                fontWeight: 800,
                fontSize: "35px",
                marginTop: "-0.5em",
                lineHeight: 1.3,
                color: "white",
              }}
            >
              Bends
            </h1>
          </Card>
        </Link>
      </Col>
      <Col sm={24} md={8}>
        <Link to="/Gallery-all">
          <Card
            className="category-card-hover"
            style={{
              margin: "1em",
              padding: "1em",
              height: "15em",

              borderRadius: "1em",
              border: "none",
              textAlign: "center",
              backgroundImage: `url(${Cat2})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          >
            <img
              style={{
                margin: "auto",
                marginTop: "4em",
                textAlign: "center",
              }}
            ></img>
            <h1
              className="Mulish"
              style={{
                fontWeight: 800,
                fontSize: "35px",
                marginTop: "-0.5em",
                lineHeight: 1.3,
                color: "white",
              }}
            >
              Elbows
            </h1>
          </Card>
        </Link>
      </Col>{" "}
      <Col sm={24} md={8}>
        <Link to="/Gallery-all">
          <Card
            className="category-card-hover"
            style={{
              margin: "1em",
              padding: "1em",
              height: "15em",

              borderRadius: "1em",
              border: "none",
              textAlign: "center",
              backgroundImage: `url(${Cat3})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          >
            <img
              style={{
                margin: "auto",
                marginTop: "4em",
                textAlign: "center",
              }}
            ></img>
            <h1
              className="Mulish"
              style={{
                fontWeight: 800,
                fontSize: "35px",
                marginTop: "-0.5em",
                lineHeight: 1.3,
                color: "white",
              }}
            >
              Tees
            </h1>
          </Card>
        </Link>
      </Col>
    </Row>
    <Row style={{ maxWidth: "90em", margin: "auto" }}>
      <Col sm={24} md={8}>
        <Link to="/Gallery-all">
          <Card
            className="category-card-hover"
            style={{
              margin: "1em",
              padding: "1em",
              height: "15em",

              borderRadius: "1em",
              border: "none",
              textAlign: "center",
              backgroundImage: `url(${Cat4})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          >
            <img
              style={{
                margin: "auto",
                marginTop: "4em",
                textAlign: "center",
              }}
            ></img>
            <h1
              className="Mulish"
              style={{
                fontWeight: 800,
                fontSize: "35px",
                marginTop: "-0.5em",
                lineHeight: 1.3,
                color: "white",
              }}
            >
              Y Pieces
            </h1>
          </Card>
        </Link>
      </Col>
      <Col sm={24} md={8}>
        <Link to="/Gallery-all">
          <Card
            className="category-card-hover"
            style={{
              margin: "1em",
              padding: "1em",
              height: "15em",

              borderRadius: "1em",
              border: "none",
              textAlign: "center",
              backgroundImage: `url(${Cat5})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          >
            <img
              style={{
                margin: "auto",
                marginTop: "4em",
                textAlign: "center",
              }}
            ></img>
            <h1
              className="Mulish"
              style={{
                fontWeight: 800,
                fontSize: "35px",
                marginTop: "-1em",
                lineHeight: 1.3,
                color: "white",
              }}
            >
              Concentric Reducers
            </h1>
          </Card>
        </Link>
      </Col>{" "}
      <Col sm={24} md={8}>
        <Link to="/Gallery-all">
          <Card
            className="category-card-hover"
            style={{
              margin: "1em",
              padding: "1em",
              height: "15em",

              borderRadius: "1em",
              border: "none",
              textAlign: "center",
              backgroundImage: `url(${Cat6})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          >
            <img
              style={{
                margin: "auto",
                marginTop: "4em",
                textAlign: "center",
              }}
            ></img>
            <h1
              className="Mulish"
              style={{
                fontWeight: 800,
                fontSize: "35px",
                marginTop: "-0.5em",
                lineHeight: 1.3,
                color: "white",
              }}
            >
              Eccentric Reducers
            </h1>
          </Card>
        </Link>
      </Col>
    </Row>
    <Row style={{ maxWidth: "90em", margin: "auto" }}>
      <Col sm={24} md={8}>
        <Link to="/Gallery-all">
          <Card
            className="category-card-hover"
            style={{
              margin: "1em",
              padding: "1em",
              height: "15em",

              borderRadius: "1em",
              border: "none",
              textAlign: "center",
              backgroundImage: `url(${Cat7})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          >
            <img
              style={{
                margin: "auto",
                marginTop: "4em",
                textAlign: "center",
              }}
            ></img>
            <h1
              className="Mulish"
              style={{
                fontWeight: 800,
                fontSize: "35px",
                marginTop: "-0.5em",
                lineHeight: 1.3,
                color: "white",
              }}
            >
              Laterals
            </h1>
          </Card>
        </Link>
      </Col>
      <Col sm={24} md={8}>
        <Link to="/Gallery-all">
          <Card
            className="category-card-hover"
            style={{
              margin: "1em",
              padding: "1em",
              height: "15em",

              borderRadius: "1em",
              border: "none",
              textAlign: "center",
              backgroundImage: `url(${Cat8})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          >
            <img
              style={{
                margin: "auto",
                marginTop: "4em",
                textAlign: "center",
              }}
            ></img>
            <h1
              className="Mulish"
              style={{
                fontWeight: 800,
                fontSize: "35px",
                marginTop: "-0.5em",
                lineHeight: 1.3,
                color: "white",
              }}
            >
              Expansion Loops
            </h1>
          </Card>
        </Link>
      </Col>{" "}
      <Col sm={24} md={8}>
        <Link to="/Gallery-all">
          <Card
            className="category-card-hover"
            style={{
              margin: "1em",
              padding: "1em",
              height: "15em",

              borderRadius: "1em",
              border: "none",
              textAlign: "center",
              backgroundImage: `url(${Cat9})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          >
            <img
              style={{
                margin: "auto",
                marginTop: "4em",
                textAlign: "center",
              }}
            ></img>
            <h1
              className="Mulish"
              style={{
                fontWeight: 800,
                fontSize: "35px",
                marginTop: "-0.5em",
                lineHeight: 1.3,
                color: "white",
              }}
            >
              Bellows
            </h1>
          </Card>
        </Link>
      </Col>
    </Row>
    <Row style={{ maxWidth: "90em", margin: "auto" }}>
      <Col sm={24} md={14} style={{ margin: "auto" }}>
        <Link to="/Other">
          <Card
            className="category-card-hover"
            style={{
              margin: "1em",
              padding: "1em",
              height: "15em",

              borderRadius: "1em",
              border: "none",
              textAlign: "center",
              backgroundImage: `url(${Cat10})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          >
            <img
              style={{
                margin: "auto",
                marginTop: "4em",
                textAlign: "center",
              }}
            ></img>
            <h1
              className="Mulish"
              style={{
                fontWeight: 800,
                fontSize: "35px",
                marginTop: "-0.5em",
                lineHeight: 1.3,
                color: "white",
              }}
            >
              Other
            </h1>
          </Card>
        </Link>
      </Col>
    </Row>
  </div>
);
const CertHeader = () => (
  <div>
    <Fade bottom>
      <p
        className="Mulish"
        style={{
          fontWeight: 800,
          fontSize: "18px",
          marginBottom: "-0em",
          color: "rgb(214, 54, 41)",
        }}
      >
        <img
          className="hori-line"
          style={{ marginTop: "-0.2em" }}
          src={HoriLine}
        ></img>{" "}
        {"  "}General fabrication
      </p>
      <p
        className="Mulish-center"
        style={{
          fontWeight: 800,
          fontSize: "35px",
          marginBottom: "-0em",
          lineHeight: 1.3,
          paddingRight: "3em",
        }}
      >
        We also specialise in the general fabrication of any and all steel
        products and specials.
      </p>
    </Fade>
  </div>
);
const CertParagraph = () => (
  <div className="Mulish-hidden">
    <Fade bottom>
      <p
        style={{
          fontSize: 18,
          paddingLeft: "0.8em",
          fontWeight: 700,
          marginTop: "1.5rem",
          color: "black",
        }}
      >
        Fabricated products can be lined or coated in:
      </p>{" "}
      <Row>
        <ul
          style={{
            fontSize: 16,
            color: "black",
          }}
        >
          <li>Galvanizing </li>
          <li>Different paint systems </li>
          <li>Wear resistant compounds</li>
        </ul>{" "}
        <ul
          style={{
            fontSize: 17,
            color: "black",
            //marginLeft: "2em",
          }}
        >
          <li>Polyurethane</li>
          <li>Polyethylene </li>
          <li>Rubber </li>
        </ul>
      </Row>
    </Fade>
  </div>
);

const Home = () => (
  <div>
    <Header />
    <Row
      style={{
        maxWidth: "70em",
        margin: "auto",
        textAlign: "center",
        marginTop: "3em",
      }}
    >
      <Col sm={24} md={24}>
        <ProductsHeader />
      </Col>
    </Row>{" "}
    <ProductsContent />
    <Row
      className="about-row-cert"
      style={{ maxWidth: "90em", margin: "auto", marginTop: "5em" }}
    >
      <Col sm={24} md={14}>
        <CertHeader />
      </Col>
      <Col sm={24} md={10}>
        <CertParagraph />
      </Col>
    </Row>
    <Footer />
  </div>
);

export default Home;

import React, { Component } from "react";
import { Row, Col, Form, Input, Button, Card } from "antd";
import styled from "styled-components";
import HomeImage from "../static/Home/home-img.png";
import ProductImage1 from "../static/Home/product-image-1.png";
import ProductImage2 from "../static/Home/product-image-2.png";
import ProductImage3 from "../static/Home/product-image-3.png";
import GalleryHome from "../static/Home/gallery-home-img.png";
import GalleryHomeBack from "../static/Home/gallery-home-back-img.png";
import { Modal as SuccessModal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import WingLeft from "../static/Home/wing-left.png";
import WingRight from "../static/Home/wing-right.png";
import Eifel from "../static/Home/eifel-img.png";
import LogoModal from "../static/Home/main-logo.svg";
import EifelMobile from "../static/Home/eifel-img-mobile.png";
import HoriLine from "../static/Home/hori-line.svg";
import { ArrowRightOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";

const StyledInput = styled(Input)`
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding-left: 1.2em !important;
  font-variant: tabular-nums;
  list-style: none;
  -webkit-font-feature-settings: "tnum", "tnum";
  font-feature-settings: "tnum", "tnum";
  position: relative;
  height: 60px !important;
  padding: 4px 0px ;
  background-color: white!important;
  background-image: none;
  border: none;
  border-bottom: 0px solid white;
  border-radius: 100px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  color: black!important;
  box-shadow: rgba(0, 0, 0, 0.11) 2px 8px 13px 5px !important;
  :placeholder {
    color: lightgrey !important;
  }
  :hover {
    border-bottom: 0px solid red !important;
  }
  :active{
    border-bottom: 0px red !important;
  }
  @media only screen and (max-width: 600px) {
  width:100vw;
  margin:auto;
    }
  }
`;

const { Meta } = Card;
const Title = () => (
  <Fade bottom>
    <h1
      className="mulish-header"
      style={{
        marginTop: "5rem",
        fontWeight: 1000,
        fontSize: 67,
        marginLeft: "0em",
        color: "black",
      }}
    >
      We strive to deliver
    </h1>{" "}
    <h1
      className="mulish-paragraph"
      style={{
        fontWeight: 100,
        letterSpacing: 5,
        fontSize: 45,
        marginTop: "-1.3em",

        color: "black",
      }}
    >
      the best quality service
    </h1>{" "}
    <p
      className="mulish-paragraph-small"
      style={{
        fontSize: 20,
        marginTop: "1.5rem",
        color: "black",
      }}
    >
      Specialist in Pipe fabrication & genaral steel fabricator.
    </p>{" "}
    <Fade bottom>
      <div className="home-button">
        <a href="#product-range">
          <Button
            style={{
              borderRadius: 50,
              fontWeight: 500,
              height: "3em",
              width: "11em",
              fontSize: "17px",
              backgroundColor: "#D63629",
              border: "black",
              marginTop: "1em",
            }}
            type="primary"
          >
            Our Product Range
          </Button>
        </a>
      </div>
    </Fade>
  </Fade>
);
const Image = () => (
  <Fade bottom>
    <img
      className="home-img"
      style={{ margin: "auto", marginTop: "4em", textAlign: "center" }}
      src={HomeImage}
    ></img>{" "}
  </Fade>
);
const AboutHeader = () => (
  <div id="about">
    <Fade bottom>
      <p
        className="Mulish"
        style={{
          fontWeight: 800,
          fontSize: "18px",
          marginBottom: "-0em",
          color: "rgb(214, 54, 41)",
        }}
      >
        <img
          className="hori-line"
          style={{ marginTop: "-0.2em" }}
          src={HoriLine}
        ></img>
        {"  "} About Us
      </p>
      <p
        className="Mulish-center"
        style={{
          fontWeight: 800,
          fontSize: "35px",
          marginBottom: "-0em",
          lineHeight: 1.3,
          paddingRight: "3em",
        }}
      >
        We aim to grow a business that is cost efficient and beneficial for our
        clientele.
      </p>
    </Fade>
  </div>
);
const AboutParagraph = () => (
  <div>
    <Fade bottom>
      <p
        className="Mulish"
        style={{
          fontSize: 18,
          marginTop: "1.5rem",
          color: "black",
        }}
      >
        BCC Concept Construction has over the years established itself as a
        leading supplier and fabricator of a complimentary range of high and low
        pressure steel pipes and fittings, light structural and associated works
        to the mining, construction, industrial, water, chemical and power
        industries.
      </p>{" "}
      <Link
        to="/Gallery"
        className="Mulish"
        style={{
          fontWeight: 800,
          fontSize: "16px",
          marginBottom: "-0em",
          color: "rgb(214, 54, 41)",
        }}
      >
        View our work
        <ArrowRightOutlined style={{ marginLeft: "0.5em" }} />
      </Link>
    </Fade>
  </div>
);
const ProductsHeader = () => (
  <div id="product-range">
    <Fade bottom>
      <h1
        className="Mulish-product-range"
        style={{
          margin: "auto",
          fontWeight: 800,
          fontSize: "35px",
        }}
      >
        OUR PRODUCT RANGE
      </h1>
      <p
        className="Mulish"
        style={{
          fontSize: 18,
          marginTop: "1.5rem",
          color: "black",
        }}
      >
        Our product offerings go way beyond amazing product manufacturing. We
        pride ourselves on supplying as well as maintaining professional
        customer service.
      </p>{" "}
    </Fade>
  </div>
);
const ProductsContent = () => (
  <Fade bottom>
    <Row style={{ maxWidth: "90em", margin: "auto" }}>
      <Col sm={24} md={8}>
        <Card
          style={{
            margin: "1em",
            padding: "1em",
            borderRadius: "1em",
            border: "none",
            textAlign: "center",
          }}
          // hoverable
          cover={
            <img
              className="products-img"
              style={{ margin: "auto", marginTop: "4em", textAlign: "center" }}
              src={ProductImage1}
            ></img>
          }
        >
          <Meta
            title="Flanged Pipes & Fittings"
            description={
              <span>
                {" "}
                <p
                  className="Mulish"
                  style={{
                    fontSize: 16,
                    marginTop: "1.5rem",
                    color: "black",
                  }}
                >
                  We also offer a wide range of flanged fabricated fittings and
                  pipes: Bends, Elbows, Tees, Laterals, Y Pieces, Concentric
                  Reducers, Eccentric Reducers, Expansion Loops, and Bellows.
                </p>{" "}
              </span>
            }
          />
          <Link
            to="/Flanged-Pipes-Fittings"
            className="Mulish"
            style={{
              fontWeight: 800,
              fontSize: "16px",
              border: "none",
              color: "rgb(214, 54, 41)",
            }}
          >
            View More
            <ArrowRightOutlined style={{ marginLeft: "0.5em" }} />
          </Link>
        </Card>
      </Col>
      <Col sm={24} md={8}>
        <Card
          style={{
            margin: "1em",
            padding: "1em",
            borderRadius: "1em",
            border: "none",
            textAlign: "center",
          }}
          // hoverable
          cover={
            <img
              className="products-img-1"
              style={{ margin: "auto", marginTop: "4em", textAlign: "center" }}
              src={ProductImage2}
            ></img>
          }
        >
          <Meta
            title="Coatings and Linings"
            description={
              <span>
                {" "}
                <p
                  className="Mulish"
                  style={{
                    fontSize: 16,
                    marginTop: "1.5rem",
                    color: "black",
                  }}
                >
                  Fabricated or plain products can be lined or coated in:
                  Galvanizing, many different paint systems, rubber,
                  polyurethane, polyethylene, wear resistant compound, chromium
                  carbide or cement mortar.
                </p>{" "}
              </span>
            }
          />
          <Link
            to="/Coatings-and-Linings"
            className="Mulish"
            style={{
              fontWeight: 800,
              fontSize: "16px",
              border: "none",
              color: "rgb(214, 54, 41)",
            }}
          >
            View More
            <ArrowRightOutlined style={{ marginLeft: "0.5em" }} />
          </Link>
        </Card>
      </Col>
      <Col sm={24} md={8}>
        <Card
          style={{
            margin: "1em",
            padding: "1em",
            borderRadius: "1em",
            border: "none",
            textAlign: "center",
          }}
          // hoverable
          cover={
            <img
              className="products-img-1"
              style={{ margin: "auto", marginTop: "4em", textAlign: "center" }}
              src={ProductImage3}
            ></img>
          }
        >
          <Meta
            title="Shouldered Pipe and Fittings"
            description={
              <span>
                {" "}
                <p
                  className="Mulish"
                  style={{
                    fontSize: 16,
                    marginTop: "1.5rem",
                    color: "black",
                  }}
                >
                  We are able to offer fittings, pipes and couplings to the
                  Klambon, Alvenius and Victaulic systems
                </p>{" "}
              </span>
            }
          />
          <Link
            to="/Shouldered-Pipe-and-Fittings"
            className="Mulish"
            style={{
              fontWeight: 800,
              fontSize: "16px",
              border: "none",
              color: "rgb(214, 54, 41)",
            }}
          >
            View More
            <ArrowRightOutlined style={{ marginLeft: "0.5em" }} />
          </Link>
        </Card>
      </Col>
      <Col
        span={24}
        style={{ textAlign: "center" }}
        className="view-more-button"
      >
        <Link to="/Other" className="Mulish">
          <Button
            className="other-button"
            style={{
              borderRadius: 50,
              fontWeight: 500,
              height: "3em",
              width: "15em",
              fontSize: "17px",
              backgroundColor: "#D63629",
              border: "black",
              marginTop: "0em",
            }}
            type="primary"
          >
            {" "}
            View other Products
          </Button>
        </Link>
      </Col>
    </Row>
  </Fade>
);
const CertHeader = () => (
  <div>
    <Fade bottom>
      <p
        className="Mulish"
        style={{
          fontWeight: 800,
          fontSize: "18px",
          marginBottom: "-0em",
          color: "rgb(214, 54, 41)",
        }}
      >
        <img
          className="hori-line"
          style={{ marginTop: "-0.2em" }}
          src={HoriLine}
        ></img>{" "}
        {"  "}
        Certification and Regulations
      </p>
      <p
        className="Mulish-center"
        style={{
          fontWeight: 800,
          fontSize: "35px",
          marginBottom: "-0em",
          lineHeight: 1.3,
          paddingRight: "3em",
        }}
      >
        BCC takes the necessary steps to comply with relevant laws, policies and
        regulations.
      </p>
    </Fade>
  </div>
);
const CertParagraph = () => (
  <div>
    <Fade bottom>
      <p
        className="Mulish"
        style={{
          fontSize: 18,
          marginTop: "1.5rem",
          color: "black",
        }}
      >
        Our company endeavors to keep up to date with the increasing number of
        regulations and need for operational transparency in the industry. We
        pride ourselves in sending out quality controlled work welded only by
        fully certified staff.
      </p>{" "}
    </Fade>
  </div>
);
const GalleryHeader = () => (
  <div>
    <Fade bottom>
      <img
        className="gallery-banner-img"
        style={{ margin: "auto", textAlign: "center" }}
        src={GalleryHome}
      ></img>{" "}
    </Fade>
  </div>
);
const GalleryParagraph = () => (
  <div style={{}} className="gallery-banner">
    <Fade bottom>
      <p
        className="Mulish"
        style={{
          fontWeight: 800,
          fontSize: "50px",
          marginBottom: "-0em",
          lineHeight: 1.3,
          color: "white",
        }}
      >
        View Our Work Gallery
      </p>
      <p
        className="Mulish"
        style={{
          fontSize: 18,
          marginTop: "1.5rem",
          color: "white",
        }}
      >
        We aim to grow a business that is cost efficient and beneficial for our
        clientele.
      </p>{" "}
      <Link to="/Gallery">
        <Button
          style={{
            borderRadius: 50,
            fontWeight: 500,
            height: "3em",
            width: "9em",
            fontSize: "17px",
            backgroundColor: "#D63629",
            border: "black",
            marginTop: "1em",
          }}
          type="primary"
        >
          View Gallery
        </Button>
      </Link>
    </Fade>
  </div>
);
const ContactHeader = () => (
  <div id="contact-us">
    <Fade bottom>
      <h1
        className="Mulish"
        style={{
          margin: "auto",
          fontWeight: 800,
          fontSize: "35px",
        }}
      >
        CONTACT US
      </h1>
      <p
        className="Mulish"
        style={{
          fontSize: 18,
          marginTop: "1.5rem",
          color: "black",
        }}
      >
        Fill in all fields, hit send and we’ll get in touch with you.
      </p>{" "}
    </Fade>
  </div>
);
//const ContactForm = () => (
//  <Form
//    onFinish={this.handleSubmit}
//    initialValues={{
//      remember: true,
//    }}
//    name="wrap"
//    labelCol={{ flex: "110px" }}
//    labelAlign="left"
//    labelWrap
//    wrapperCol={{ flex: 1 }}
//    colon={false}
//  >
//    <Row style={{}}>
//      <Col sm={24} md={11}>
//        <Form.Item name="name" rules={[{ required: true }]}>
//          <StyledInput placeholder="Company Name" />
//        </Form.Item>
//      </Col>
//      <Col sm={0} md={2}></Col>
//      <Col sm={24} md={11}>
//        <Form.Item name="email" rules={[{ required: true }]}>
//          <StyledInput placeholder="Company Email" />
//        </Form.Item>
//      </Col>
//      <Col sm={24} md={24}>
//        <Form.Item name="message" rules={[{ required: true }]}>
//          <StyledInput placeholder="Message" />
//        </Form.Item>
//      </Col>
//    </Row>
//    <Form.Item>
//      <Button
//        style={{
//          borderRadius: 50,
//          fontWeight: 500,
//          height: "3em",
//          width: "9em",
//          fontSize: "17px",
//          backgroundColor: "#D63629",
//          border: "black",
//          marginTop: "1em",
//        }}
//        type="primary"
//        htmlType="submit"
//      >
//        Send
//      </Button>
//    </Form.Item>
//  </Form>
//);
class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      successModal: false,
    };
    this.changeValue = this.changeValue.bind(this);
    this.onFinish = this.onFinish.bind(this);
  }
  handleTabClick = (key) => {
    this.props.history.push(`/${key}`); // < == router router v4
  };
  componentDidMount() {
    document.title = "BCC Concept Construction";
    document.documentElement.scrollTop = 0;
  }
  onChange = (e) => {
    this.setState({
      placement: e.target.value,
    });
  };
  onChange = (value) => {
    this.setState({
      inputValue: value,
    });
  };
  showModal = () => {
    this.setState({
      visiblePopup: true,
    });
  };
  handleOk = (e) => {
    console.log(e);
    this.setState({
      visiblePopup: false,
    });
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visiblePopup: false,
    });
  };
  handleClickDemo(form) {
    this.setState({
      FormChangeDemo: form,
    });
  }
  handleClickCall(form) {
    this.setState({
      FormChangeCall: form,
    });
  }
  showModalDrone = () => {
    this.setState({
      visiblePopupDrone: true,
    });
  };
  handleOk1 = (e) => {
    console.log(e);
    this.setState({
      visiblePopupDrone: false,
    });
  };

  handleCancel1 = (e) => {
    console.log(e);
    this.setState({
      visiblePopupDrone: false,
    });
  };
  handleSubmit = (values) => {
    this.onFinish(values);
  };
  changeValue = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleCloseSuccessModal = () => {
    this.setState({ successModal: false });
    window.location.assign("./");
  };

  onFinish = (values) => {
    const MSG = {
      to: ["bcccon@mweb.co.za"],
      from: "info@bcc.co.za",
      //to: ["ianels182@gmail.com", "monique@moniqs-interiors.com"],
      subject: "New contact request",
      text: " ",
      html: `
    
      <style type="text/css">  
      body, #bodyTable{background-color:white}
      #emailHeader{background-color:white}
      #emailBody{background-color:#FFFFFF; border:1px solid #CCCCCC;}
      #emailFooter{background-color:#E1E1E1;}      
</style>
<body bgcolor="#E1E1E1" leftmargin="0" marginwidth="0" topmargin="0" marginheight="0" offset="0">
<center style="background-color:#E1E1E1;">
<table border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="bodyTable" style="table-layout: fixed;max-width:100% !important;width: 100% !important;min-width: 100% !important;">
  <tr>
    <td align="center" valign="top" id="bodyCell">      
      <table bgcolor="#E1E1E1" border="0" cellpadding="0" cellspacing="0" width="500" id="emailHeader">
        <!-- HEADER ROW // -->
        <tr>
          <td align="center" valign="top">
            <!-- CENTERING TABLE // -->
            <table border="0" cellpadding="0" cellspacing="0" width="100%">
              <tr>
                <td align="center" valign="top">
                  <!-- FLEXIBLE CONTAINER // -->
                  <table border="0" cellpadding="10" cellspacing="0" width="500" class="flexibleContainer">
                    <tr>
                      <td valign="top" width="500" class="flexibleContainerCell">

                        <!-- CONTENT TABLE // -->
                        <table align="left" border="0" cellpadding="0" cellspacing="0" width="100%">
                          <tr>
                       
                            <td align="right" valign="middle" class="flexibleContainerBox">
                              <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width:100%;">
                                <tr>
                                  <td align="left" class="textContent">
                                    <!-- CONTENT // -->
                                   
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
            <!-- // CENTERING TABLE -->
          </td>
        </tr>
        <!-- // END -->
      </table>     
      <!-- EMAIL BODY // -->   
      <table bgcolor="#FFFFFF" border="0" cellpadding="0" cellspacing="0" width="500" id="emailBody">
        <!-- MODULE ROW // -->
        <tr>
          <td align="center" valign="top">
            <!-- CENTERING TABLE // -->
            <table border="0" cellpadding="0" cellspacing="0"   style="color:#FFFFFF;" bgcolor="#101010">
              <tr>
                <td align="center" valign="top">
                  <!-- FLEXIBLE CONTAINER // -->
                 
                  <table border="0" cellpadding="0" cellspacing="0" width="500"   class="flexibleContainer">
                    <tr>
                      <td align="center" valign="top" width="500" class="flexibleContainerCell">

                        <!-- CONTENT TABLE // -->
                      
                        <table border="0" cellpadding="0" cellspacing="0" width="100%" z-index="100" >
                               <td align="center" valign="top" width="500" class="flexibleContainerCell  ">   
                                <img alt="" src="https://i.ibb.co/C7n963J/IMG-20220201-WA0020.png" width="300" height="100" style="display: block; width:195px; max-width: 355px;; min-width: 189px;;border-radius: 8px 8px 8px 8px; font-family: Lato, Helvetica, Arial, sans-serif; color: #9AA31E; font-size: 18px;margin-top: 18px;" border="0">     <tr>
                            <td align="center" valign="top" class="textContent">
                              <h1 style="color:white;line-height:100%;font-family:Helvetica,Arial,sans-serif;font-size:35px;font-weight:normal;margin-bottom:20px;text-align:center;">Contact Request</h1>
                              <h2 style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:17px;margin-bottom:10px;color: white;line-height:135%;">You have received a new contact request from <br /> BCC website</h2>
                            </td>
                          </tr>
                        </table>
                        <!-- // CONTENT TABLE -->
                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
            <!-- // CENTERING TABLE -->
          </td>
        </tr>
        <!-- MODULE ROW // -->
        <tr mc:hideable>
          <td align="center" valign="top">
            <!-- CENTERING TABLE // -->
            <table border="0" cellpadding="0" cellspacing="0" width="100%" height="300" >
              <tr>
                <td align="center" valign="top">
                  <!-- FLEXIBLE CONTAINER // -->
                  <table border="0" cellpadding="0" cellspacing="0" width="300" class="flexibleContainer">
                    <tr>
                      <td valign="top" width="500" class="flexibleContainerCell">
                        <!-- CONTENT TABLE // -->
                        <table p align="left" border="0" cellpadding="0" cellspacing="0" width="120%">
                          <tr>
                            <td align="center" valign="top" class="flexibleContainerBox">
                               <table border="0" cellspacing="0" cellpadding="0" >
            
                        <tr style="font-family: sans-serif; font-size: 17px; letter-spacing: 1px; color:black">
                          <td class="role"><br /><br /><br /><br /><br />
                          <strong> Name </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.name}<br />
                           
                               <strong> Email </strong>&nbsp;&nbsp; :                     &nbsp;&nbsp;${values.email}<br />
                               <strong> Message </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.message}
                             <br /><br /><br />
                          </td>
                        </tr>
                        <table>
                            </td>
                          </tr>
                        </table>
                        <!-- // CONTENT TABLE -->

                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
            <!-- // CENTERING TABLE -->
          </td>
        </tr>
        <!-- // MODULE ROW -->


        <!-- MODULE ROW // -->
       
<table border="0" cellpadding="0" cellspacing="0" width="100%"  style="color:#FFFFFF;" bgcolor="#101010" style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:13px;margin-bottom:-80px;color: lightgray;line-height:135%;">
              <tr>
                <td align="center" valign="top">
                
                  <table border="0" cellpadding="0" cellspacing="0" width="500"   class="flexibleContainer">
                    <tr>
                      <td align="center" valign="top" width="500" class="flexibleContainerCell">

                    
                        <table border="0" cellpadding="30" cellspacing="0" width="100%">
                      
                    
                          <tr>
                            <td align="center" valign="top" class="textContent">
        
                              <h2 style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:13px;margin-bottom:0px;color: grey;line-height:135%;">2022@BCC</h2>
               
                            </td>
                          </tr>
                        </table>
                        <!-- // CONTENT TABLE -->

                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
     `,
    };
    fetch("https://www.vcsappcloud.com/emailclient/sendmail", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify(MSG),
    }).then(async (res) => {
      if (!res.ok) {
        console.log("handle error");
      }
      console.log("handle success");
      this.setState({
        successModal: true,
        submitDisabled: true,
        name: "",
        email: "",
        message: "",
      });
    });
  };

  render() {
    return (
      <div>
        <img
          className="eifeil-img"
          src={Eifel}
          style={{
            position: "absolute",
            top: 0,
            left: 100,
            opacity: 0.8,
            zIndex: -1,
            width: "45vw",
          }}
        ></img>
        <img
          className="eifeil-img-mobile"
          src={EifelMobile}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            opacity: 0.8,
            zIndex: -1,
            width: "100vw",
          }}
        ></img>
        <Row style={{ maxWidth: "90em", margin: "auto" }}>
          <Col sm={24} md={13} className="home-col">
            <Title />
          </Col>
          <Col sm={24} md={11}>
            <Image />
          </Col>
        </Row>
        <Row
          className="about-row"
          style={{ maxWidth: "90em", margin: "auto", marginTop: "7em" }}
        >
          <Col sm={24} md={12}>
            <AboutHeader />
          </Col>
          <Col sm={24} md={12}>
            <AboutParagraph />
          </Col>
        </Row>
        <Row
          style={{
            maxWidth: "70em",
            margin: "auto",
            textAlign: "center",
            marginTop: "7em",
          }}
        >
          <Col sm={24} md={24}>
            <ProductsHeader />
          </Col>
        </Row>{" "}
        <ProductsContent />
        <Row
          className="about-row-cert"
          style={{ maxWidth: "90em", margin: "auto", marginTop: "7em" }}
        >
          <Col sm={24} md={12}>
            <CertHeader />
          </Col>
          <Col sm={24} md={12}>
            <CertParagraph />
          </Col>
        </Row>
        <div
          className="gallery-container"
          style={{
            padding: "2em",
            backgroundColor: "black",
            borderRadius: "2em",
            maxWidth: "90em",
            margin: "auto",
            marginTop: "7em",
          }}
        >
          <Row
            style={{
              maxWidth: "90em",
              margin: "auto",
              backgroundImage: `url(${GalleryHomeBack})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              borderRadius: "2em",
              padding: "2.5em",
              border: "4px solid white",
              backgroundColor: "transparent",
            }}
          >
            <Col sm={24} md={14}>
              <GalleryHeader />
            </Col>

            <Col
              className="gallery-paragraph"
              sm={24}
              md={10}
              style={{ padding: "2em" }}
            >
              <GalleryParagraph />
            </Col>
          </Row>
        </div>
        <Row
          style={{
            maxWidth: "70em",
            margin: "auto",
            textAlign: "center",
            marginTop: "7em",
          }}
        >
          <Col sm={24} md={24}>
            <ContactHeader />
          </Col>
        </Row>
        <Row
          style={{
            maxWidth: "50em",
            margin: "auto",
            textAlign: "center",
            marginTop: "2em",
          }}
        >
          <Col sm={24} md={24}>
            <Form
              onFinish={this.handleSubmit}
              initialValues={{
                remember: true,
              }}
              name="wrap"
              labelCol={{ flex: "110px" }}
              labelAlign="left"
              labelWrap
              wrapperCol={{ flex: 1 }}
              colon={false}
            >
              <Row style={{}}>
                <Col sm={24} md={11}>
                  <Form.Item name="name" rules={[{ required: true }]}>
                    <StyledInput placeholder="Company Name" />
                  </Form.Item>
                </Col>
                <Col sm={0} md={2}></Col>
                <Col sm={24} md={11}>
                  <Form.Item name="email" rules={[{ required: true }]}>
                    <StyledInput placeholder="Company Email" />
                  </Form.Item>
                </Col>
                <Col sm={24} md={24}>
                  <Form.Item name="message" rules={[{ required: true }]}>
                    <StyledInput placeholder="Message" />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item>
                <Button
                  style={{
                    borderRadius: 50,
                    fontWeight: 500,
                    height: "3em",
                    width: "9em",
                    fontSize: "17px",
                    backgroundColor: "#D63629",
                    border: "black",
                    marginTop: "1em",
                  }}
                  type="primary"
                  htmlType="submit"
                >
                  Send
                </Button>
              </Form.Item>
            </Form>
          </Col>
          <img
            className="contact-image"
            style={{
              marginTop: "-3em",
              left: 0,
              zIndex: -1,
              position: "absolute",
            }}
            src={WingLeft}
          ></img>
          <img
            className="hori-line"
            style={{
              marginTop: "-15em",
              right: 0,
              zIndex: -1,
              position: "absolute",
            }}
            src={WingRight}
          ></img>{" "}
        </Row>{" "}
        <SuccessModal
          open={this.state.successModal}
          onClose={this.handleCloseSuccessModal}
          center
        >
          <img src={LogoModal} alt="Logo" style={{ width: "65%" }} />
          <h3
            style={{
              marginTop: "1em",
              color: "black",
              textAlign: "center",
            }}
          >
            Thank you for contacting
            <br />
            <span style={{ color: "rgb(214, 54, 41)" }}>
              BCC Concept Construction.
            </span>{" "}
            <br />
            <span style={{ color: "black " }}>
              Our product specialist will be in touch shortly.
            </span>{" "}
            <br />
          </h3>{" "}
          <br />
          <Button
            onClick={this.handleCloseSuccessModal}
            style={{
              margin: "auto",
              height: "2.9em",
              fontFamily: "Nunito",
              fontSize: "1rem",
              fontWeight: 500,
              borderRadius: "5em",
              backgroundColor: "#D63629",
              border: "none",
              marginBottom: "1em",
              paddingLeft: "3em",
              paddingRight: "3em",
              marginRight: "0em",
              textShadow: "none",
            }}
            type="primary"
          >
            Close
          </Button>{" "}
        </SuccessModal>
      </div>
    );
  }
}

export default Home;

import React, { Component } from "react";
import { Row, Col, Form, Input, Button, Card, Image } from "antd";

class Other extends Component {
  render() {
    const array = [
      "5",
      "7",
      "8",
      "9",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
      "23",
      "24",
      "25",
      "26",
      "27",
      "28",
      "29",
      "30",
    ];

    const images = array.map((image) => {
      return (
        <Col sm={24} md={8} style={{ margin: "auto", marginTop: "2em" }}>
          <Image
            style={{
              margin: "auto",
              height: "17em",
              width: "27em",
              borderRadius: "0.5em",
              objectFit: "cover",
            }}
            key={image}
            src={require(`../../static/Other/${image}.jpg`)}
          />{" "}
        </Col>
      );
    });

    return (
      <div>
        <Row style={{ maxWidth: "90em", margin: "auto" }}>{images}</Row>
      </div>
    );
  }
}

export default Other;

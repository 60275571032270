import React from "react";
import { Row, Col } from "antd";
import OtherImages from "../../static/Other/index.js";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { ArrowLeftOutlined } from "@ant-design/icons";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";

const ProductsHeader = () => (
  <div>
    <Fade bottom>
      <h1
        className="Mulish"
        style={{
          margin: "auto",
          fontWeight: 800,
          fontSize: "35px",
        }}
      >
        OTHERS
      </h1>
      <p
        className="Mulish"
        style={{
          fontSize: 18,
          marginTop: "1.5rem",
          color: "black",
        }}
      >
        BCC Concept Construction offers a vast product range.
      </p>{" "}
    </Fade>
  </div>
);
const ProductsContent = () => (
  <div>
    <OtherImages />
  </div>
);

const Other = () => (
  <div>
    <Header />
    <Row
      style={{
        maxWidth: "70em",
        margin: "auto",
        textAlign: "center",
        marginTop: "3em",
      }}
    >
      <Col sm={24} md={24}>
        <ProductsHeader />
      </Col>
    </Row>{" "}
    <ProductsContent />
    <Row
      style={{
        maxWidth: "70em",
        margin: "auto",
        textAlign: "center",
        marginTop: "3em",
      }}
    >
      <Link
        to="/Gallery"
        className="Mulish"
        style={{
          border: "none",
          fontWeight: 800,
          fontSize: "16px",
          margin: "auto",
          color: "rgb(214, 54, 41)",
        }}
      >
        <ArrowLeftOutlined style={{ marginRight: "0.5em" }} /> Back to Gallery
      </Link>
    </Row>{" "}
    <Footer />
  </div>
);

export default Other;

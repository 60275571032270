import React, { Component } from "react";
import { Row, Col, Form, Input, Button, Card, Image } from "antd";

class ShoulderedImages extends Component {
  render() {
    const array = [
      "1",
      "2",
      "3",
      "4",
      //"5",
      //"6",
      //"7",
      //"8",
      //"9",
      //"10",
      //"11",
      //"12",
      //"13",
      //"14",
      //"15",
      //"16",
      //"17",
      "18",
      "19",
      "20",
      "21",
      "22",
      "23",
      "24",
      "25",
      "26",
      "27",
      "28",
      "29",
      "30",
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
      "49",
    ];

    const images = array.map((image) => {
      return (
        <Col sm={24} md={8} style={{ margin: "auto", marginTop: "2em" }}>
          <Image
            style={{
              margin: "auto",
              height: "17em",
              width: "27em",
              borderRadius: "0.5em",
              objectFit: "cover",
            }}
            key={image}
            src={require(`../../static/Shouldered-pipes-and-fittings/${image}.JPG`)}
          />{" "}
        </Col>
      );
    });

    return (
      <div>
        <Row style={{ maxWidth: "90em", margin: "auto" }}>{images}</Row>
      </div>
    );
  }
}

export default ShoulderedImages;
